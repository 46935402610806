<template>
    <div class="grid">
        <button
            class="btn btn-danger btn-link relative"
            :class="{'confirmation': confirmDelete}"
            @click="handleDeleteClick"
            :data-message="message"
        >
            <i class="marso-icon-trash text-2xl"></i>
        </button>
    </div>
</template>

<script setup lang="ts">
import {ref, Ref} from "vue";

const confirmDelete: Ref<boolean> = ref(false);
const emit = defineEmits(['remove']);

const props = defineProps({
    message: {
        type: String,
        required: false
    }
});

const handleDeleteClick = () => {
    if (confirmDelete.value) {
        emit('remove');
        confirmDelete.value = false;
        return;
    }

    confirmDelete.value = true;
    setTimeout(() => {
        confirmDelete.value = false;
    }, 5000);
}

</script>

<style scoped>
.confirmation::before {
    position: absolute;
    display: block;
    content: attr(data-message);
    opacity: 0.9;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    white-space: nowrap;
    top: -100%;
    @apply bg-red
}

.confirmation::after {
    content: "";
    position: absolute;
    display: block;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    top: -5px;
    opacity: 0.9;
    @apply border-t-red
}

</style>
